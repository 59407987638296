import Radiobox from "Common/Radiobox";
import withRouter from "Common/withRouter";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { customSelectTheme, styleObj } from "helpers/common";
import { City, Country, State } from "country-state-city";
import Select from "react-select";
import Tooltip from "rc-tooltip";

const NPR2 = ({ validation, diffThanOwner, setDiffThanOwner }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    console.log("********00000", inputRef.current);
    if (inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Restrict the search to geographical location types
          // types: ["address"], // Restrict to address types
          // fields: ["address_components"], // Get only address components
        }
      );

      autocomplete.addListener("place_changed", () => {
        console.log("********11111");
        const place = autocomplete.getPlace();
        if (place.address_components) {
          const addressComponents = place.address_components;
          const addressDetails = {
            street: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
          };

          addressComponents?.forEach((component: any) => {
            const types = component.types;
            if (types.includes("sublocality_level_1")) {
              addressDetails.area = component.long_name;
            } else if (types.includes("locality")) {
              addressDetails.city = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              addressDetails.state = component.short_name;
            } else if (types.includes("country")) {
              addressDetails.country = component.short_name;
            } else if (types.includes("postal_code")) {
              addressDetails.zipcode = component.long_name;
            } else if (
              types.includes("street_number") ||
              types.includes("route")
              // || types.includes("neighborhood")
            ) {
              addressDetails.street += ` ${component.long_name}`;
            }
          });

          validation.setFieldValue(
            "searchAddress",
            place.formatted_address || ""
          );
          validation.setFieldValue(
            "serviceAddress",
            addressDetails.street?.trim() || ""
          );
          validation.setFieldValue(
            "serviceExtendedAddress",
            addressDetails.area || ""
          );
          validation.setFieldValue("serviceCity", addressDetails.city || "");
          validation.setFieldValue("serviceState", addressDetails.state || "");
          validation.setFieldValue(
            "serviceCountry",
            addressDetails.country || ""
          );
          validation.setFieldValue(
            "servicePostalCode",
            addressDetails.zipcode || ""
          );
        }
      });
    }
  }, [inputRef.current]);

  const [allCountry, setAllCountry] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const [allState2, setAllState2] = useState<any>([]);
  const [allCity, setAllCity] = useState<any>([]);
  const [allCity2, setAllCity2] = useState<any>([]);
  const [diffThanOwnerAdd, setDiffThanOwnerAdd] = useState<any>(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleSubmit = () => {
    if (phoneNumber && isValidPhone) {
      validation.setFieldValue("phoneNumberToBePorted", [
        ...validation.values.phoneNumberToBePorted,
        phoneNumber,
      ]);
      setPhoneNumber("");
      setError("");
    } else {
      !phoneNumber
        ? setError("Please Enter Phone number")
        : !isValidPhone
        ? setError("Phone number is not valid")
        : setError("");
    }
  };

  useEffect(() => {
    setAllCountry(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (validation?.values?.ownersCountry) {
      const temp = State.getStatesOfCountry(validation?.values?.ownersCountry);
      setAllState(temp);
    }
  }, [validation?.values?.ownersCountry]);

  useEffect(() => {
    if (validation?.values?.serviceCountry) {
      const temp = State.getStatesOfCountry(validation?.values?.serviceCountry);
      setAllState2(temp);
    }
  }, [validation?.values?.serviceCountry]);

  useEffect(() => {
    if (validation?.values?.ownersState) {
      const temp = City.getCitiesOfState(
        validation?.values?.ownersCountry,
        validation?.values?.ownersState
      );
      setAllCity(temp);
    }
  }, [validation?.values?.ownersState]);

  useEffect(() => {
    if (validation?.values?.serviceState) {
      const temp = City.getCitiesOfState(
        validation?.values?.serviceCountry,
        validation?.values?.serviceState
      );
      setAllCity2(temp);
    }
  }, [validation?.values?.serviceState]);

  return (
    <React.Fragment>
      <Row>
        <Col lg={6}>
          <Form.Label htmlFor="portingRequestType">
            Business Or Personal <span className="text-primary">*</span>
          </Form.Label>
          {validation.touched.portingRequestType &&
          validation.errors.portingRequestType ? (
            <p className="text-primary">
              {validation.errors.portingRequestType}
            </p>
          ) : null}
          <div className="d-flex mb-3">
            <div className="me-5">
              <Radiobox
                label="Business"
                id="Business"
                name="portingRequestType"
                value="Business"
                checked={validation?.values?.portingRequestType === "Business"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue(
                    "portingRequestType",
                    e.target.value
                  )
                }
              />
            </div>
            <div>
              <Radiobox
                label="Personal"
                id="Personal"
                name="portingRequestType"
                value="Personal"
                checked={validation?.values?.portingRequestType === "Personal"}
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue(
                    "portingRequestType",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        </Col>
        <Col lg={6}>
          {validation?.values?.portingRequestType === "Business" ? (
            <div className="mb-3">
              <Form.Label htmlFor="businessName">
                Business Name{" "}
                {validation?.values?.portingRequestType === "Business" ? (
                  <span className="text-primary">*</span>
                ) : null}
              </Form.Label>
              <Form.Control
                disabled={validation?.values?.portingRequestType !== "Business"}
                type="text"
                id="businessName"
                name="businessName"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation?.values?.businessName || ""}
                isInvalid={
                  validation?.touched?.businessName &&
                  validation?.errors?.businessName
                    ? true
                    : false
                }
              />
              {validation?.touched?.businessName &&
              validation?.errors?.businessName ? (
                <Form.Control.Feedback type="invalid">
                  {validation?.errors?.businessName}
                </Form.Control.Feedback>
              ) : null}
            </div>
          ) : null}
        </Col>

        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="portingService">
            Are you porting just messaging services or entire service?{" "}
            <span className="text-primary">*</span>
          </Form.Label>
          {validation.touched.portingService &&
          validation.errors.portingService ? (
            <p className="text-primary">{validation.errors.portingService}</p>
          ) : null}
          <div className="d-flex">
            <div className="me-5">
              <Radiobox
                label="Messaging Service"
                id="Messaging Service"
                name="portingService"
                value="Messaging Service"
                checked={
                  validation?.values?.portingService === "Messaging Service"
                }
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("portingService", e.target.value)
                }
              />
            </div>
            <div>
              <Radiobox
                label="Entire Service"
                id="Entire Service"
                name="portingService"
                value="Entire Service"
                checked={
                  validation?.values?.portingService === "Entire Service"
                }
                onChange={(e: { target: { value: string } }) =>
                  validation?.setFieldValue("portingService", e.target.value)
                }
              />
            </div>
          </div>
          {validation?.values?.portingService === "Entire Service" && (
            <p className="text-muted mb-0">
              Please wait to cancel your existing service until the port has
              been confirmed.
            </p>
          )}
        </Col>

        <div className="mb-1">
          <h5>Owner's Account Information</h5>
          <p>
            This information must match what is on file with the existing
            carrier.
          </p>
        </div>
        <Col lg={12} className="mb-4">
          <Row className="border bg-light-gray  rounded-3 p-3">
            <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersName">
                  Owner's Name <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="ownersName"
                  name="ownersName"
                  onChange={(e) => {
                    validation.setFieldValue("ownersName", e.target.value);
                    !diffThanOwner &&
                      validation.setFieldValue(
                        "authorizedPersonsName",
                        e.target.value
                      );
                  }}
                  onBlur={validation.handleBlur}
                  value={validation?.values?.ownersName || ""}
                  isInvalid={
                    validation?.touched?.ownersName &&
                    validation?.errors?.ownersName
                      ? true
                      : false
                  }
                />
                {validation.touched?.ownersName &&
                validation.errors?.ownersName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation.errors?.ownersName}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersEmail">
                  Owner's Email Address
                </Form.Label>
                <Form.Control
                  type="email"
                  id="ownersEmail"
                  name="ownersEmail"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation?.values?.ownersEmail || ""}
                  isInvalid={
                    validation.touched.ownersEmail &&
                    validation.errors.ownersEmail
                      ? true
                      : false
                  }
                />
                {validation.touched.ownersEmail &&
                validation.errors.ownersEmail ? (
                  <Form.Control.Feedback type="invalid">
                    {validation.errors.ownersEmail}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <Form.Label htmlFor="portingRequestType">
                Is The Billing Contact Different Than The Owner?
              </Form.Label>
              <div className="d-flex mb-3">
                <div className="me-5">
                  <Radiobox
                    label="Yes"
                    id="yes"
                    name="diffThanOwner"
                    value="True"
                    checked={diffThanOwner}
                    onChange={() => setDiffThanOwner(true)}
                  />
                </div>
                <div>
                  <Radiobox
                    label="No"
                    id="no"
                    name="diffThanOwner"
                    value="False"
                    checked={!diffThanOwner}
                    onChange={() => {
                      setDiffThanOwner(false);
                      validation.setFieldValue(
                        "authorizedPersonsName",
                        validation?.values?.ownersName
                      );
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg={6} />
            <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="authorizedPersonsName">
                  Authorized Person's Name (Billing Contact){" "}
                  {diffThanOwner ? (
                    <span className="text-primary">*</span>
                  ) : null}
                </Form.Label>
                <Form.Control
                  disabled={!diffThanOwner}
                  type="text"
                  id="authorizedPersonsName"
                  name="authorizedPersonsName"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation?.values?.authorizedPersonsName || ""}
                  isInvalid={
                    validation.touched?.authorizedPersonsName &&
                    validation.errors?.authorizedPersonsName
                      ? true
                      : false
                  }
                />
                {validation.touched?.authorizedPersonsName &&
                validation.errors?.authorizedPersonsName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation.errors?.authorizedPersonsName}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="phoneNumber">
                  Phone Number{" "}
                  {diffThanOwner ? (
                    <span className="text-primary">*</span>
                  ) : null}
                </Form.Label>
                <br />
                <Form.Control
                  disabled={!diffThanOwner}
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  isInvalid={
                    validation.touched.phoneNumber &&
                    validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                validation.errors.phoneNumber ? (
                  <Form.Control.Feedback type="invalid">
                    {validation.errors.phoneNumber}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersStreetAddress">
                  Address Line 1 <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="ownersStreetAddress"
                  name="ownersStreetAddress"
                  onChange={(e) => {
                    validation.setFieldValue(
                      "ownersStreetAddress",
                      e.target.value
                    );
                    !diffThanOwnerAdd &&
                      validation.setFieldValue(
                        "serviceAddress",
                        e.target.value
                      );
                  }}
                  onBlur={validation.handleBlur}
                  value={validation?.values?.ownersStreetAddress || ""}
                  isInvalid={
                    validation?.touched?.ownersStreetAddress &&
                    validation?.errors?.ownersStreetAddress
                      ? true
                      : false
                  }
                />
                {validation.touched?.ownersStreetAddress &&
                validation.errors?.ownersStreetAddress ? (
                  <Form.Control.Feedback type="invalid">
                    {validation.errors?.ownersStreetAddress}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersExtendedAddress">
                  Address Line 2
                </Form.Label>
                <Form.Control
                  type="text"
                  id="ownersExtendedAddress"
                  name="ownersExtendedAddress"
                  onChange={(e) => {
                    validation.setFieldValue(
                      "ownersExtendedAddress",
                      e.target.value
                    );
                    !diffThanOwnerAdd &&
                      validation.setFieldValue(
                        "serviceExtendedAddress",
                        e.target.value
                      );
                  }}
                  onBlur={validation.handleBlur}
                  value={validation?.values?.ownersExtendedAddress || ""}
                  isInvalid={
                    validation?.touched?.ownersExtendedAddress &&
                    validation?.errors?.ownersExtendedAddress
                      ? true
                      : false
                  }
                />
                {validation.touched?.ownersExtendedAddress &&
                validation.errors?.ownersExtendedAddress ? (
                  <Form.Control.Feedback type="invalid">
                    {validation.errors?.ownersExtendedAddress}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersCountry">
                  Country <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  menuPortalTarget={document.body}
                  name="ownersCountry"
                  styles={styleObj(
                    validation?.touched?.ownersCountry &&
                      validation?.errors?.ownersCountry
                  )}
                  theme={customSelectTheme}
                  options={
                    allCountry?.length > 0
                      ? allCountry?.map(
                          (dt: { name: string; isoCode: string }) => ({
                            label: dt?.name,
                            value: dt?.isoCode,
                          })
                        )
                      : []
                  }
                  onChange={(e: { value: string }) => {
                    validation.setFieldValue(
                      "ownersCountry",
                      e.value ? e.value : ""
                    );
                    validation.setFieldValue("ownersState", "");
                    validation.setFieldValue("ownersCity", "");
                    validation.setFieldValue("ownersPostalCode", "");
                    !diffThanOwnerAdd &&
                      validation.setFieldValue(
                        "serviceCountry",
                        e.value ? e.value : ""
                      );
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("serviceState", "");
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("serviceCity", "");
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("servicePostalCode", "");
                  }}
                  value={{
                    label: allCountry?.find(
                      (dt: { isoCode: string }) =>
                        dt?.isoCode === validation?.values?.ownersCountry
                    )?.name,
                    value: validation?.values?.ownersCountry,
                  }}
                />
                {validation?.touched?.ownersCountry &&
                validation?.errors?.ownersCountry ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation?.errors?.ownersCountry}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersState">
                  State <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  menuPortalTarget={document.body}
                  name="ownersState"
                  styles={styleObj(
                    validation?.touched?.ownersState &&
                      validation?.errors?.ownersState
                  )}
                  theme={customSelectTheme}
                  isDisabled={!validation?.values?.ownersCountry}
                  options={
                    allState?.length > 0
                      ? allState?.map(
                          (dt: { name: string; isoCode: string }) => ({
                            label: dt?.name,
                            value: dt?.isoCode,
                          })
                        )
                      : []
                  }
                  onChange={(e: { value: string }) => {
                    validation.setFieldValue("ownersState", e.value);
                    validation.setFieldValue("ownersCity", "");
                    validation.setFieldValue("ownersPostalCode", "");
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("serviceState", e.value);
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("serviceCity", "");
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("servicePostalCode", "");
                  }}
                  value={{
                    label: allState?.find(
                      (dt: { isoCode: string }) =>
                        dt?.isoCode === validation?.values?.ownersState
                    )?.name,
                    value: validation?.values?.ownersState,
                  }}
                />
                {validation?.touched?.ownersState &&
                validation?.errors?.ownersState ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation?.errors?.ownersState}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersCity">
                  City <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  menuPortalTarget={document.body}
                  name="ownersCity"
                  styles={styleObj(
                    validation?.touched?.ownersCity &&
                      validation?.errors?.ownersCity
                  )}
                  theme={customSelectTheme}
                  isDisabled={!validation?.values?.ownersState}
                  options={
                    allCity?.length > 0
                      ? allCity?.map((dt: { name: string }) => ({
                          label: dt?.name,
                          value: dt?.name,
                        }))
                      : []
                  }
                  onChange={(e: { value: string }) => {
                    validation.setFieldValue("ownersCity", e.value);
                    validation.setFieldValue("ownersPostalCode", "");
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("serviceCity", e.value);
                    !diffThanOwnerAdd &&
                      validation.setFieldValue("servicePostalCode", "");
                  }}
                  value={{
                    label: validation?.values?.ownersCity,
                    value: validation?.values?.ownersCity,
                  }}
                />
                {validation?.touched?.ownersCity &&
                validation?.errors?.ownersCity ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation?.errors?.ownersCity}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Form.Label htmlFor="ownersPostalCode">
                  Zip Code <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="ownersPostalCode"
                  name="ownersPostalCode"
                  placeholder="Enter zipcode"
                  onChange={(e) => {
                    validation.setFieldValue(
                      "ownersPostalCode",
                      e.target.value
                    );
                    !diffThanOwnerAdd &&
                      validation.setFieldValue(
                        "servicePostalCode",
                        e.target.value
                      );
                  }}
                  onBlur={validation.handleBlur}
                  value={validation?.values?.ownersPostalCode || ""}
                  isInvalid={
                    validation?.touched?.ownersPostalCode &&
                    validation?.errors?.ownersPostalCode
                      ? true
                      : false
                  }
                />
                {validation?.touched?.ownersPostalCode &&
                validation?.errors?.ownersPostalCode ? (
                  <Form.Control.Feedback type="invalid">
                    {validation?.errors?.ownersPostalCode}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </Col>

        <div className="mt-3 mb-1">
          <h5>Service Address</h5>
          <p>
            The physical location of the phone number on record with the current
            carrier.
          </p>
        </div>
        <Col lg={12} className="mb-4">
          <Row className="border bg-light-gray rounded-3 p-3">
            <Col lg={12}>
              <Form.Label htmlFor="portingRequestType">
                Is The Service Address Different Than The Billing Address?
              </Form.Label>
              <div className="d-flex">
                <div className="me-5">
                  <Radiobox
                    label="Yes"
                    id="badress-yes"
                    name="diffThanOwnerAdd"
                    value="True"
                    checked={diffThanOwnerAdd}
                    onChange={() => setDiffThanOwnerAdd(true)}
                  />
                </div>
                <div>
                  <Radiobox
                    label="No"
                    id="badress-no"
                    name="diffThanOwnerAdd"
                    value="False"
                    checked={!diffThanOwnerAdd}
                    onChange={() => {
                      setDiffThanOwnerAdd(false);
                      validation.setFieldValue(
                        "serviceAddress",
                        validation?.values?.ownersStreetAddress
                      );
                      validation.setFieldValue(
                        "serviceExtendedAddress",
                        validation?.values?.ownersExtendedAddress
                      );
                      validation.setFieldValue(
                        "serviceCity",
                        validation?.values?.ownersCity
                      );
                      validation.setFieldValue(
                        "serviceState",
                        validation?.values?.ownersState
                      );
                      validation.setFieldValue(
                        "serviceCountry",
                        validation?.values?.ownersCountry
                      );
                      validation.setFieldValue(
                        "servicePostalCode",
                        validation?.values?.ownersPostalCode
                      );
                    }}
                  />
                </div>
              </div>
            </Col>
            {diffThanOwnerAdd && (
              <>
                <Col lg={12}>
                  <div className="mb-3 mt-3">
                    <Form.Label htmlFor="searchAddress">
                      Search for an address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      ref={inputRef}
                      id="searchAddress"
                      name="searchAddress"
                      placeholder="Search for address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation?.values?.searchAddress || ""}
                      isInvalid={
                        validation?.touched?.searchAddress &&
                        validation?.errors?.searchAddress
                          ? true
                          : false
                      }
                    />
                    {validation.touched?.searchAddress &&
                    validation.errors?.searchAddress ? (
                      <Form.Control.Feedback type="invalid">
                        {validation.errors?.searchAddress}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <p className="text-center">-- Or enter it manually --</p>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="serviceAddress">
                      Address Line 1 <span className="text-primary">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="serviceAddress"
                      name="serviceAddress"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation?.values?.serviceAddress || ""}
                      isInvalid={
                        validation?.touched?.serviceAddress &&
                        validation?.errors?.serviceAddress
                          ? true
                          : false
                      }
                    />
                    {validation.touched?.serviceAddress &&
                    validation.errors?.serviceAddress ? (
                      <Form.Control.Feedback type="invalid">
                        {validation.errors?.serviceAddress}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="serviceExtendedAddress">
                      Address Line 2
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="serviceExtendedAddress"
                      name="serviceExtendedAddress"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation?.values?.serviceExtendedAddress || ""}
                      isInvalid={
                        validation?.touched?.serviceExtendedAddress &&
                        validation?.errors?.serviceExtendedAddress
                          ? true
                          : false
                      }
                    />
                    {validation.touched?.serviceExtendedAddress &&
                    validation.errors?.serviceExtendedAddress ? (
                      <Form.Control.Feedback type="invalid">
                        {validation.errors?.serviceExtendedAddress}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Form.Label htmlFor="serviceCity">
                      City <span className="text-primary">*</span>
                    </Form.Label>
                    <Select
                      name="serviceCity"
                      menuPortalTarget={document.body}
                      styles={styleObj(
                        validation?.touched?.serviceCity &&
                          validation?.errors?.serviceCity
                      )}
                      theme={customSelectTheme}
                      isDisabled={!validation?.values?.serviceState}
                      options={
                        allCity2?.length > 0
                          ? allCity2?.map((dt: { name: string }) => ({
                              label: dt?.name,
                              value: dt?.name,
                            }))
                          : []
                      }
                      onChange={(e: { value: string }) => {
                        validation.setFieldValue("serviceCity", e.value);
                        validation.setFieldValue("servicePostalCode", "");
                      }}
                      value={{
                        label: validation?.values?.serviceCity,
                        value: validation?.values?.serviceCity,
                      }}
                    />
                    {validation?.touched?.serviceCity &&
                    validation?.errors?.serviceCity ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {validation?.errors?.serviceCity}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Form.Label htmlFor="serviceState">
                      State <span className="text-primary">*</span>
                    </Form.Label>
                    <Select
                      name="serviceState"
                      menuPortalTarget={document.body}
                      styles={styleObj(
                        validation?.touched?.serviceState &&
                          validation?.errors?.serviceState
                      )}
                      theme={customSelectTheme}
                      isDisabled={!validation?.values?.serviceCountry}
                      options={
                        allState2?.length > 0
                          ? allState2?.map(
                              (dt: { name: string; isoCode: string }) => ({
                                label: dt?.name,
                                value: dt?.isoCode,
                              })
                            )
                          : []
                      }
                      onChange={(e: { value: string }) => {
                        validation.setFieldValue("serviceState", e.value);
                        validation.setFieldValue("serviceCity", "");
                        validation.setFieldValue("servicePostalCode", "");
                      }}
                      value={{
                        label: allState2?.find(
                          (dt: { isoCode: string }) =>
                            dt?.isoCode === validation?.values?.serviceState
                        )?.name,
                        value: validation?.values?.serviceState,
                      }}
                    />
                    {validation?.touched?.serviceState &&
                    validation?.errors?.serviceState ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {validation?.errors?.serviceState}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Form.Label htmlFor="serviceCountry">
                      Country <span className="text-primary">*</span>
                    </Form.Label>
                    <Select
                      name="serviceCountry"
                      menuPortalTarget={document.body}
                      styles={styleObj(
                        validation?.touched?.serviceCountry &&
                          validation?.errors?.serviceCountry
                      )}
                      theme={customSelectTheme}
                      options={
                        allCountry?.length > 0
                          ? allCountry?.map(
                              (dt: { name: string; isoCode: string }) => ({
                                label: dt?.name,
                                value: dt?.isoCode,
                              })
                            )
                          : []
                      }
                      onChange={(e: { value: string }) => {
                        validation.setFieldValue(
                          "serviceCountry",
                          e.value ? e.value : ""
                        );
                        validation.setFieldValue("serviceState", "");
                        validation.setFieldValue("serviceCity", "");
                        validation.setFieldValue("servicePostalCode", "");
                      }}
                      value={{
                        label: allCountry?.find(
                          (dt: { isoCode: string }) =>
                            dt?.isoCode === validation?.values?.serviceCountry
                        )?.name,
                        value: validation?.values?.serviceCountry,
                      }}
                    />
                    {validation?.touched?.serviceCountry &&
                    validation?.errors?.serviceCountry ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {validation?.errors?.serviceCountry}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Form.Label htmlFor="servicePostalCode">
                      Zip Code <span className="text-primary">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="servicePostalCode"
                      name="servicePostalCode"
                      placeholder="Enter zipcode"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation?.values?.servicePostalCode || ""}
                      isInvalid={
                        validation?.touched?.servicePostalCode &&
                        validation?.errors?.servicePostalCode
                          ? true
                          : false
                      }
                    />
                    {validation?.touched?.servicePostalCode &&
                    validation?.errors?.servicePostalCode ? (
                      <Form.Control.Feedback type="invalid">
                        {validation?.errors?.servicePostalCode}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Col>

        <div className="mb-1">
          <h5>Phone Number(s) to port</h5>
          <p>Please enter the phone numbers you wish to transfer</p>
        </div>
        <div>
          <Col lg={12} className="mb-4">
            <Row className="border bg-light-gray  rounded-3 p-3">
              <Col lg={6}>
                <div className="">
                  <Form.Label htmlFor="phoneNumberToBePorted">
                    Phone Number(s)
                    <span className="text-primary">*</span>
                  </Form.Label>

                  {validation.errors?.phoneNumberToBePorted ? (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mb-1 text-primary"
                    >
                      {validation.errors?.phoneNumberToBePorted}
                    </Form.Control.Feedback>
                  ) : null}
                  <div className="d-flex">
                    <Form.Control
                      type="number"
                      id="phoneNumberToBePorted"
                      name="phoneNumberToBePorted"
                      placeholder="Enter phone number"
                      onChange={(e) => {
                        const reg = /^[1-9]\d{1,3}[1-9]\d{6,14}$/;
                        const val = e.target.value;
                        const isValid = reg.test(val);

                        !isValid
                          ? setError("Phone Number is Not Valid")
                          : setError("");

                        setPhoneNumber(val);
                        setIsValidPhone(isValid);
                      }}
                      value={phoneNumber || ""}
                    />
                    <Button
                      variant="secondary"
                      className="ms-2 d-flex align-items-center"
                      onClick={handleSubmit}
                    >
                      <i className="bx bx-plus me-1"></i> Add
                    </Button>
                  </div>
                  {error ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {error}
                    </Form.Control.Feedback>
                  ) : null}
                  <div className="d-flex flex-wrap mt-2">
                    {validation.values.phoneNumberToBePorted?.map(
                      (dt: string, i2: number) => {
                        return (
                          <span
                            className="details-tag mt-1 mr-2 d-flex align-items-center"
                            key={i2}
                          >
                            {dt}
                            <i
                              className="ri-close-circle-fill cursor-pointer fs-md text-primary ms-2"
                              onClick={() => {
                                const temp = [
                                  ...validation.values.phoneNumberToBePorted,
                                ];
                                temp.splice(i2, 1);
                                validation.setFieldValue(
                                  "phoneNumberToBePorted",
                                  temp
                                );
                              }}
                            />
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col lg={6}>
              <Form.Label htmlFor="cellNumberOrLandline">
                Cell Number Or Landline/VOIP{" "}
                <span className="text-primary">*</span>
              </Form.Label>
              {validation.touched.cellNumberOrLandline &&
              validation.errors.cellNumberOrLandline ? (
                <p className="text-primary">
                  {validation.errors.cellNumberOrLandline}
                </p>
              ) : null}
              <div className="d-flex mb-3">
                <div className="me-5">
                  <Radiobox
                    label="Cell"
                    id="Cell"
                    name="cellNumberOrLandline"
                    value="Cell"
                    checked={
                      validation?.values?.cellNumberOrLandline === "Cell"
                    }
                    onChange={(e: { target: { value: string } }) =>
                      validation?.setFieldValue(
                        "cellNumberOrLandline",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div>
                  <Radiobox
                    label="Landline/VOIP"
                    id="Landline"
                    name="cellNumberOrLandline"
                    value="Landline/VOIP"
                    checked={
                      validation?.values?.cellNumberOrLandline ===
                      "Landline/VOIP"
                    }
                    onChange={(e: { target: { value: string } }) =>
                      validation?.setFieldValue(
                        "cellNumberOrLandline",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              {validation?.values?.cellNumberOrLandline === "Cell" ? (
                <div className="mb-3">
                  <Form.Label htmlFor="accountNumber">
                    Account Number{" "}
                    {validation?.values?.cellNumberOrLandline === "Cell" ? (
                      <span className="text-primary">*</span>
                    ) : null}
                  </Form.Label>
                  <Form.Control
                    disabled={
                      validation?.values?.cellNumberOrLandline !== "Cell"
                    }
                    type="text"
                    id="accountNumber"
                    name="accountNumber"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation?.values?.accountNumber || ""}
                    isInvalid={
                      validation.touched.accountNumber &&
                      validation.errors.accountNumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched?.accountNumber &&
                  validation.errors?.accountNumber ? (
                    <Form.Control.Feedback type="invalid">
                      {validation.errors?.accountNumber}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
              ) : null}
            </Col>
            <Col lg={6}>
              {validation.values.portingService === "Entire Service" ? (
                <div className="mb-3">
                  <Form.Label
                    htmlFor="pinOrPasscode"
                    className="d-flex align-items-end position-relative"
                  >
                    PIN / Passcode{" "}
                    {validation.values.portingService === "Entire Service" ? (
                      <span className="text-primary mx-1">*</span>
                    ) : null}
                    <Tooltip
                      placement="bottomLeft"
                      overlay={
                        <p className="mb-0">
                          Often, the losing carrier will require you call them
                          to obtain an account PIN, to figure out why they
                          are/try to prevent losing your business. Getting this
                          ahead of time makes the process go much quicker."
                        </p>
                      }
                    >
                      <i
                        className="ri-information-line text-secondary fs-2xl ms-3 position-absolute"
                        style={{ left: 100, bottom: -4 }}
                      ></i>
                    </Tooltip>
                  </Form.Label>
                  <Form.Control
                    disabled={
                      validation.values.portingService !== "Entire Service"
                    }
                    type="text"
                    id="pinOrPasscode"
                    name="pinOrPasscode"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation?.values?.pinOrPasscode || ""}
                    isInvalid={
                      validation.touched?.pinOrPasscode &&
                      validation.errors?.pinOrPasscode
                        ? true
                        : false
                    }
                  />
                  {validation?.touched?.pinOrPasscode &&
                  validation?.errors?.pinOrPasscode ? (
                    <Form.Control.Feedback type="invalid">
                      {validation?.errors?.pinOrPasscode}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(NPR2);
