import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Button,
  Form,
  Row,
  Col,
  Spinner,
  Alert,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Datatable from "../../Common/Datatable";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  asyncTagsList,
  capitalizeString,
  customSelectTheme,
  getSortIcon,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import { activePhoneNumbersThunk, openModal } from "slices/thunk";
import Tooltip from "rc-tooltip";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSearchParams } from "react-router-dom";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";
import { setLocal } from "helpers/services/auth/auth";

const initialFilterValues = {
  signalHouseSID: "",
  phoneNumber: "",
  groupId: "",
  subGroupId: [],
  brandId: [],
  campaignId: [],
  numberStatus: "",
  tenDLCStatus: "",
  friendlyName: "",
  qTags: [],
};

const MyNumbers = (props: any) => {
  document.title = "Signal House Portal Your Numbers";

  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const brandId = searchParams.get("brandId");
  const campaignId = searchParams.get("campaignId");
  const sortColumn = searchParams.get("sortColumn") || "";
  const isSortAsc = searchParams.get("sortDir") || "asc";

  const isSortClick = useRef(false);
  const [isInit, setIsInit] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const selectProfile = createSelector(
    (state: any) => state.OwnNumbers,
    (state: any) => state.Groups,
    (ownNumbers, groups) => ({
      loading: ownNumbers.loading,
      dataObj: ownNumbers.dataObj,
      error: ownNumbers.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, dataObj, groupDetails } = useSelector(selectProfile);

  const handleRowSelection = (row: any, isChecked: any) => {
    if (isChecked) {
      setSelectedRows([...selectedRows, row.phoneNumber]);
      setSelectedTags(
        (prevTags: any) => [...prevTags, ...(row.tags || [])] as any
      );
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== row.phoneNumber));
      setSelectedTags((prevTags) =>
        prevTags.filter((tag) => !(row.tags || []).includes(tag))
      );
    }
  };

  const handleSelectAll = (isChecked: any) => {
    if (isChecked) {
      setSelectedRows(
        dataObj?.records?.map((row: any) => row.phoneNumber) || []
      );
      setSelectedTags(
        dataObj?.records?.reduce(
          (acc: any, row: any) => [...acc, ...(row.tags || [])],
          []
        )
      );
    } else {
      setSelectedRows([]);
      setSelectedTags([]);
    }
  };

  const columns = [
    {
      name: (
        <Form.Check
          type="checkbox"
          checked={
            dataObj?.records?.length > 0 &&
            selectedRows.length === dataObj?.records?.length
          }
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      width: "50px",
      cell: (row: any) => (
        <Form.Check
          type="checkbox"
          checked={selectedRows.includes(row.phoneNumber)}
          onChange={(e) => handleRowSelection(row, e.target.checked)}
        />
      ),
    },
    {
      fieldName: "phoneNumber",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "phoneNumber" ? "hideIcon" : ""
          }`}
        >
          Number{" "}
          <span className="table-header-icon">
            {getSortIcon("phoneNumber", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { phoneNumber: number | string }) =>
        row.phoneNumber || "",
      cell: (row: { phoneNumber: number | string }) => (
        <span className="text-primary">{row.phoneNumber || ""}</span>
      ),
      minWidth: "130px",
      sortable: true,
    },
    {
      fieldName: "friendlyName",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "friendlyName" ? "hideIcon" : ""
          }`}
        >
          Friendly Name{" "}
          <span className="table-header-icon">
            {getSortIcon("friendlyName", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { friendlyName: any }) => row.friendlyName || "",
      cell: (row: { friendlyName: string; phoneNumber: string }) => {
        const truncatedName =
          row.friendlyName && row.friendlyName.length > 15
            ? `${row.friendlyName.slice(0, 15)}...`
            : row.friendlyName;
        return (
          <span>
            {row.friendlyName && row.friendlyName.length > 15 ? (
              <Tooltip placement="top" overlay={row.friendlyName}>
                <span>{truncatedName}</span>
              </Tooltip>
            ) : (
              <span>{truncatedName}</span>
            )}
            {/* <i
              className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
              style={{
                WebkitTextStroke: "0.5px",
              }}
              onClick={() => {
                dispatch(
                  openModal({
                    path: "AddFriendlyName",
                    data: {
                      title: "Add Friendly Name",
                      detailsfriendlyName: row.friendlyName || "",
                      phoneNumber: row?.phoneNumber || "",
                      getOwnPhoneNumber: () =>
                        handleGetData(currPage, rowsPerPage, validation.values),
                      footer: false,
                    },
                  })
                );
              }}
            /> */}
          </span>
        );
      },
      minWidth: "200px",
      sortable: true,
    },
    {
      fieldName: "tenDLCWhiteListStatus",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "tenDLCWhiteListStatus" ? "hideIcon" : ""
          }`}
        >
          Messaging Status{" "}
          <span className="table-header-icon">
            {getSortIcon("tenDLCWhiteListStatus", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { tenDLCWhiteListStatus: any }) =>
        row.tenDLCWhiteListStatus || "",
      cell: (cell: { tenDLCWhiteListStatus: any }) => {
        const status = cell.tenDLCWhiteListStatus?.toLowerCase() || "N/A";
        switch (status) {
          case "mt":
          case "false":
          case "in-progress":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(
                  cell.tenDLCWhiteListStatus?.replaceAll("-", " ") || ""
                )}
              </span>
            );
          case "ready":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus || "")}
              </span>
            );
          case "pending":
            return (
              <span className="d-flex align-items-center text-black">
                <span className="badge border border-2 border-white rounded-circle bg-black p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus || "")}
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(status)}
              </span>
            );
        }
      },
      minWidth: "150px",
      sortable: true,
    },
    {
      fieldName: "numberStatus",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "numberStatus" ? "hideIcon" : ""
          }`}
        >
          Number Status{" "}
          <span className="table-header-icon">
            {getSortIcon("numberStatus", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { numberStatus: any }) => row.numberStatus || "",
      cell: (cell: { numberStatus: any }) => {
        switch (cell.numberStatus) {
          case "Inactive":
            return (
              <span
                className="badge bg-body-secondary border border-primary text-primary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Inactive
              </span>
            );
          case "Active":
            return (
              <span
                className="badge bg-body-secondary border border-secondary text-secondary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          case "inprogress":
            return (
              <span
                className="badge bg-warning text-black text-warning text-start fs-sm"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(String(cell.numberStatus) || "N/A")}
              </span>
            );
        }
      },
      minWidth: "130px",
      sortable: true,
    },
    {
      fieldName: "tags",
      name: (
        <span
          className={`font-weight-bold fs-sm ${
            sortColumn === "tags" ? "hideIcon" : ""
          }`}
        >
          Tags{" "}
          <span className="table-header-icon">
            {getSortIcon("tags", sortColumn, isSortAsc)}
          </span>
        </span>
      ),
      selector: (row: { tags: any }) => row.tags?.join(", ") || "",
      cell: (row: { tags: string[] }) => {
        const maxVisibleTags = 2;
        const maxTagLength = 10;
        const truncatedTags = row.tags?.slice(0, maxVisibleTags) || [];
        const hasMoreTags = row.tags?.length > maxVisibleTags;

        return (
          <div className="d-flex flex-wrap">
            {truncatedTags.map((tag: string, i: number) => {
              const isTagTruncated = tag.length > maxTagLength;
              const displayTag = isTagTruncated
                ? `${tag.slice(0, maxTagLength)}...`
                : tag;

              return (
                <Tooltip
                  placement="top"
                  overlay={
                    <div className="d-flex flex-wrap">
                      <span className="">{displayTag}</span>
                    </div>
                  }
                >
                  <span className="details-tag mr-1">{displayTag}</span>
                </Tooltip>
              );
            })}
            {hasMoreTags && (
              <Tooltip
                overlayStyle={{
                  borderColor: "white",
                  opacity: 1,
                }}
                showArrow={false}
                overlayInnerStyle={{
                  backgroundColor: "white",
                  opacity: 1,
                }}
                placement="top"
                overlay={
                  <div className="d-flex flex-wrap">
                    {row.tags.map((tag: string, i: number) => (
                      <span className="details-tag mr-1" key={i}>
                        {tag}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="details-tag more-tags">
                  +{row.tags.length - maxVisibleTags} more
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
      minWidth: "200px",
      grow: 2,
      wrap: true,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Capabilities</span>,
      minWidth: "150px",
      selector: (row: { capabilities: any }) =>
        JSON.stringify(row.capabilities) || "",
      cell: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
        };
      }) => {
        return (
          <div
            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
            style={{
              gap: "20px",
            }}
          >
            {row?.capabilities?.voice && (
              <Tooltip placement="bottom" overlay={"Voice"}>
                <i className="bi bi-telephone fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.sms && (
              <Tooltip placement="bottom" overlay={"SMS"}>
                <i className="bi bi-chat-left-text fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.mms && (
              <Tooltip placement="bottom" overlay={"MMS"}>
                <i className="bi bi-file-image fs-lg"></i>
              </Tooltip>
            )}
          </div>
        );
      },

      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      minWidth: "110px",
      cell: (row: {
        phoneNumber?: number | string;
        signalHouseSID?: number | string;
      }) => {
        return (
          <Button
            className="btn btn-sm"
            variant="outline-secondary"
            onClick={() => {
              setLocal(
                "numberSortingRoute",
                `${props.router.location?.pathname}${props.router.location?.search}`
              );
              props.router.navigate(
                `/mynumbers/${row?.phoneNumber || ""}/${
                  row?.signalHouseSID || ""
                }`
              );
            }}
          >
            Configure
          </Button>
        );
      },
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      setIsSubmitClicked(true);
      setCurrPage(1);
      handleGetData(1, rowsPerPage, values);
    },
  });

  useEffect(() => {
    setLocal("numberSortingRoute", "");
    setCurrPage(1);
    handleGetData(1, rowsPerPage, {
      ...validation.values,
      groupId: groupDetails?.records?.[0]?.group_id || "",
      subGroupId: subGroupId ? [subGroupId] : [],
      brandId: brandId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
            },
          ]
        : [],
      campaignId: campaignId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
              campaignId: campaignId || "",
            },
          ]
        : [],
    });
    setIsInit(false);
  }, []);

  useEffect(() => {
    subGroupId && validation.setFieldValue("subGroupId", [subGroupId]);
    brandId &&
      validation.setFieldValue("brandId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
        },
      ]);
    campaignId &&
      validation.setFieldValue("campaignId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
          campaignId: campaignId || "",
        },
      ]);
  }, [subGroupId, brandId, campaignId]);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    setCurrPage(1);
    !isInit && handleGetData(1, rowsPerPage, validation.values);
    isSortClick.current = false;
  }, [sortColumn, isSortAsc]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      activePhoneNumbersThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          sortBy: sortColumn || "",
          sortOrder: sortColumn ? isSortAsc : "",
          subGroupId:
            otherParams?.subGroupId.length > 0 ? otherParams?.subGroupId : "",
          brandId:
            otherParams?.brandId.length > 0
              ? otherParams?.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            otherParams?.campaignId.length > 0
              ? otherParams?.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          qTags:
            otherParams?.qTags.length > 0
              ? otherParams?.qTags?.map((dt: any) => dt.tag)
              : "",
        })
      )
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    !isSortClick.current && handleGetData(page, rowsPerPage, validation.values);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows, validation.values);
  };

  const handleSort = (column: any) => {
    isSortClick.current = true;
    if (sortColumn === column.fieldName) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        sortColumn: column.fieldName || "",
        sortDir: isSortAsc === "asc" ? "desc" : "asc",
      });
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        sortColumn: column.fieldName || "",
        sortDir: "asc",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Your Numbers"
            isBack={Boolean(subGroupId)}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown2"
                show={open}
                onToggle={() => setOpen(!open)}
              >
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{ flex: "1 1 0%" }}
                >
                  <div className="d-flex align-items-center flex-wrap">
                    {validation.values.signalHouseSID && (
                      <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                        Phone Number SID: {validation.values.signalHouseSID}
                      </span>
                    )}
                    {validation.values.phoneNumber && (
                      <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                        Phone Number: {validation.values.phoneNumber}
                      </span>
                    )}

                    {validation.values.subGroupId?.length > 0 && (
                      <Tooltip
                        placement="top"
                        overlay={validation.values.subGroupId?.join(", ")}
                        overlayClassName="subgroup-tooltip"
                      >
                        <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                          {validation.values.subGroupId.length === 1 ? (
                            `Subgroup Id: ${validation.values.subGroupId[0]}`
                          ) : (
                            <>
                              Subgroup Id: {validation.values.subGroupId[0]}
                              {` +${
                                validation.values.subGroupId.length - 1
                              } More`}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}
                    {validation.values.brandId?.length > 0 && (
                      <Tooltip
                        placement="top"
                        overlay={validation.values.brandId
                          ?.map((dt: any) => dt.brandId)
                          ?.join(", ")}
                        overlayClassName="brand-tooltip"
                      >
                        <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                          {validation.values.brandId.length === 1 ? (
                            `Brand Id: ${validation.values.brandId[0].brandId}`
                          ) : (
                            <>
                              Brand Id: {validation.values.brandId[0].brandId}
                              {` +${validation.values.brandId.length - 1} More`}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}
                    {validation.values.campaignId?.length > 0 && (
                      <Tooltip
                        placement="top"
                        overlay={validation.values.campaignId
                          ?.map((dt: any) => dt.campaignId)
                          ?.join(", ")}
                        overlayClassName="campaign-tooltip"
                      >
                        <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                          {validation.values.campaignId.length === 1 ? (
                            `Campaign Id: ${validation.values.campaignId[0].campaignId}`
                          ) : (
                            <>
                              Campaign Id:{" "}
                              {validation.values.campaignId[0].campaignId}
                              {` +${
                                validation.values.campaignId.length - 1
                              } More`}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}

                    {validation.values.numberStatus && (
                      <Tooltip
                        placement="top"
                        overlay={`Number Status: ${validation.values.numberStatus}`}
                        overlayClassName="status-tooltip"
                      >
                        <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                          Number Status: {validation.values.numberStatus}
                        </span>
                      </Tooltip>
                    )}
                    {validation.values.tenDLCStatus && (
                      <Tooltip
                        placement="top"
                        overlay={`Number Status: ${validation.values.tenDLCStatus}`}
                        overlayClassName="status-tooltip"
                      >
                        <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                          Messaging Status: {validation.values.tenDLCStatus}
                        </span>
                      </Tooltip>
                    )}

                    {validation.values.friendlyName && (
                      <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                        Friendly Name: {validation.values.friendlyName}
                      </span>
                    )}
                    {validation.values.qTags?.length > 0 && (
                      <Tooltip
                        placement="top"
                        overlay={validation.values.qTags
                          ?.map((tagObj: any) => tagObj.tag)
                          ?.join(", ")}
                        overlayClassName="status-tooltip"
                      >
                        <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                          {validation.values.qTags.length === 1 ? (
                            `Tags: ${validation.values.qTags[0]?.tag}`
                          ) : (
                            <>
                              Tags: {validation.values.qTags[0]?.tag}{" "}
                              {` +${validation.values.qTags.length - 1} More`}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <Dropdown.Toggle variant="secondary">
                    <i className="bx bx-filter-alt me-1"></i>
                  </Dropdown.Toggle>
                </div>
                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">
                          Phone Number SID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="phoneNumberSID"
                          placeholder="Search by Phone Number SID"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "signalHouseSID",
                              e.target.value
                            );
                          }}
                          value={validation.values.signalHouseSID}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="phoneNumber">
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="phoneNumber"
                          placeholder="Search by Phone Number"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "phoneNumber",
                              e.target.value
                            );
                          }}
                          value={validation.values.phoneNumber}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <GroupSelect
                          isDisabled={!validation.values.groupId}
                          key={validation.values.groupId}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name +
                            " - " +
                            option?.sub_group_id
                          }
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("qTags", []);
                            } else {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <BrandSelect
                          key={
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) =>
                            option?.displayName + " - " + option?.brandId
                          }
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("qTags", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId"
                          )}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt?.campaignId || "",
                                }))
                              );
                              validation.setFieldValue("qTags", []);
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="numberStatus">
                          Number Status
                        </Form.Label>
                        <Select
                          name="numberStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.numberStatus &&
                              validation?.errors?.numberStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            { value: "Active", label: "Active" },
                            { value: "Inactive", label: "Inactive" },
                          ]}
                          placeholder="Select status"
                          value={
                            validation.values.numberStatus
                              ? {
                                  label: validation.values.numberStatus,
                                  value: validation.values.numberStatus,
                                }
                              : null
                          }
                          onChange={(e: any) => {
                            if (e) {
                              validation.setFieldValue("numberStatus", e.value);
                            } else {
                              validation.setFieldValue("numberStatus", "");
                            }
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="tenDLCStatus">
                          Messaging Status
                        </Form.Label>
                        <Select
                          name="tenDLCStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.tenDLCStatus &&
                              validation?.errors?.tenDLCStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            { value: "Pending", label: "Pending" },
                            { value: "Ready", label: "Ready" },
                            { value: "In-progress", label: "In-progress" },
                          ]}
                          placeholder="Select status"
                          value={
                            validation.values.tenDLCStatus
                              ? {
                                  label: validation.values.tenDLCStatus,
                                  value: validation.values.tenDLCStatus,
                                }
                              : null
                          }
                          onChange={(e: any) => {
                            if (e) {
                              validation.setFieldValue("tenDLCStatus", e.value);
                            } else {
                              validation.setFieldValue("tenDLCStatus", "");
                            }
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="friendlyName">
                          Friendly Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="friendlyName"
                          placeholder="Enter Friendly Name"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "friendlyName",
                              e.target.value
                            );
                          }}
                          value={validation.values.friendlyName}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="qTags">Tags</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.campaignId
                              ?.map((dt: any) => dt.campaignId)
                              .toString() ||
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "qTags"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.qTags &&
                              validation?.errors?.qTags
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.qTags
                              ? validation.values.qTags
                              : null
                          }
                          loadOptions={asyncTagsList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                              campaignId:
                                validation.values.campaignId.length > 0
                                  ? validation.values.campaignId?.map(
                                      (dt: any) => dt.campaignId
                                    )
                                  : "",
                              isReleased: false,
                            }),
                            "tagSearch"
                          )}
                          getOptionValue={(option: any) => option?.tag}
                          getOptionLabel={(option: any) => option?.tag}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.qTags === validation.values.qTags
                            ) {
                              return;
                            }

                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.campaignId.find(
                                        (item: any) =>
                                          item.campaignId === dt.campaignId &&
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt.campaignId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.campaignId]
                                )
                              );
                              validation.setFieldValue("qTags", option);
                            } else {
                              validation.setFieldValue("qTags", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          setIsSubmitClicked(false);
                          validation.setValues(initialFilterValues);
                          validation.resetForm();
                          validation.setFieldValue(
                            "groupId",
                            groupDetails?.records?.[0]?.group_id
                          );
                          setCurrPage(1);
                          handleGetData(1, rowsPerPage);
                        }}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : dataObj?.records?.length === 0 ? (
                  <Alert variant="primary" className="text-center">
                    {isSubmitClicked
                      ? "No numbers were found matching your search parameters."
                      : "You don't have any purchased numbers.\nPlease buy some first."}
                  </Alert>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(dataObj?.records) ? dataObj?.records : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={dataObj?.totalRecords}
                    sortColumn={sortColumn}
                    isSortAsc={isSortAsc}
                    onSort={handleSort}
                    customOptions={
                      selectedRows?.length > 0 && (
                        <div
                          className="d-flex flex-column flex-md-row align-items-center justify-content-md-center border h-auto p-3"
                          style={{
                            backgroundColor: "white",
                            boxShadow: "0 1px 1px rgba(0, 0, 0, 0.3)",
                            borderRadius: "10px",
                            width: "40%",
                            minHeight: "auto",
                            position: "absolute",
                            top: "83%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: "10",
                          }}
                        >
                          <Button
                            className={`btn btn-outline-primary me-2 ${
                              selectedRows?.length < 1
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                            type="button"
                            disabled={selectedRows?.length < 1}
                            onClick={() => {
                              dispatch(
                                openModal({
                                  path: "AddFriendlyName",
                                  data: {
                                    title: "Edit Friendly Names",
                                    phoneNumber: selectedRows,
                                    getOwnPhoneNumber: () =>
                                      handleGetData(
                                        currPage,
                                        rowsPerPage,
                                        validation.values
                                      ),
                                    footer: false,
                                  },
                                })
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Edit{" "}
                            {selectedRows?.length > 1
                              ? "Friendly Names"
                              : "Friendly Name"}
                          </Button>
                          <Button
                            className={`btn btn-outline-primary me-2 ${
                              selectedRows?.length < 1
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                            type="button"
                            disabled={selectedRows?.length < 1}
                            onClick={() => {
                              dispatch(
                                openModal({
                                  path: "EditTags",
                                  data: {
                                    title: "Edit Tags",
                                    detailsTags: selectedTags,
                                    outSideData: true,
                                    phoneNumber: selectedRows || [],
                                    getOwnPhoneNumber: () =>
                                      handleGetData(
                                        currPage,
                                        rowsPerPage,
                                        validation.values
                                      ),
                                    footer: false,
                                  },
                                })
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Edit {selectedRows?.length > 1 ? "Tags" : "Tag"}
                          </Button>
                        </div>
                      )
                    }
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MyNumbers);
