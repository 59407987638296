import {
  authUserApi,
  fileUploadApi,
  getFileApi,
  updateAuthUserApi,
  authHistoryApi,
  getUserStepsApi,
  updateUserStepsApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  apiLoading3,
  authUserSuccessful,
  authUserPermissions,
  authUserFailed,
  updateAuthUserSuccessful,
  updateAuthUserFailed,
  authUserLogout,
  authHistorySuccessful,
  authHistoryFailed,
  userStepsDataSuccessful,
  userStepsDataFailed,
  updateUserStepsSuccessful,
  updateUserStepsFailed,
} from "./reducer";
import { toast } from "react-toastify";
import { getAllGroupsSuccessful } from "slices/groups/reducer";

export const getAuthUserThunk = (cb?: any) => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await authUserApi();

    if (data?.userId) {
      dispatch(authUserSuccessful(data));
      data?.roles?.[0] && dispatch(authUserPermissions(data?.roles?.[0]));
      localStorage.setItem("gid", data?.groupId);
      dispatch(
        getAllGroupsSuccessful({
          records: [
            {
              group_name: data?.groupName || data?.companyName,
              group_id: data?.groupId,
            },
          ],
        })
      );
      cb && cb(data);
    } else {
      dispatch(authUserFailed(data));
      // toast.error(data?.message);
    }
  } catch (error: any) {
    dispatch(authUserFailed(error));
    // toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const fileUploadThunk =
  (file: any, isFormData?: boolean, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await fileUploadApi(file, isFormData);

      if (data?.length) {
        cb && cb(data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(updateAuthUserFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getFileThunk =
  (path: string, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getFileApi(path);

      if (data) {
        dispatch(updateAuthUserSuccessful({}));
        cb &&
          cb(
            new File([data], `file.${data?.type?.split("/").pop()}`, {
              type: data?.type === "text/xml" ? "application/pdf" : data?.type,
            })
          );
      } else {
        dispatch(updateAuthUserFailed({ message: "Something went wrong" }));
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(updateAuthUserFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateAuthUserThunk =
  (user: any, cb?: any, hideSuccessToast?: boolean) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await updateAuthUserApi(user);

      if (data?.message) {
        dispatch(updateAuthUserSuccessful({}));
        !hideSuccessToast && toast.success(data?.message);
        dispatch(getAuthUserThunk());
        cb && cb();
      } else {
        dispatch(updateAuthUserFailed(data?.message));
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(updateAuthUserFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const authUserLogoutThunk = () => async (dispatch: any) => {
  dispatch(authUserLogout());
};

// Thunk for fetching authentication history records
export const fetchAuthHistoryThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await authHistoryApi(page, recordsPerPage, otherParams);

      if (data?.code || data?.message) {
        dispatch(authHistoryFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(authHistorySuccessful(data));
      }
    } catch (error: any) {
      dispatch(authHistoryFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getUserStepsThunk =
  (isCompletedSteps?: boolean) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getUserStepsApi();

      if (data?.code || data?.errorCode) {
        dispatch(userStepsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(userStepsDataSuccessful(data));

        !isCompletedSteps &&
          Object.values(data).filter((item) => item !== true).length === 0 &&
          dispatch(updateAuthUserThunk({ completedSteps: true }));
      }
    } catch (error: any) {
      dispatch(userStepsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateUserStepsThunk =
  (payload: any, isCompletedSteps?: boolean) => async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await updateUserStepsApi(payload);

      if (data?.code || data?.errorCode) {
        dispatch(updateUserStepsFailed(data?.message));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(updateUserStepsSuccessful({}));
        // toast.success(data?.message);
        dispatch(getUserStepsThunk(isCompletedSteps));
      }
    } catch (error: any) {
      dispatch(updateUserStepsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
